import React from 'react';
import styles from './Spacer.module.css';

function Spacer({children, direction="column", gap="16px", width="100%"}) {
  return (
    <div style={{
        display: "flex",
        flexDirection: direction,
        gap: gap,
        width: width
    }} className={styles.spacer}>{children}</div>
  );
}

export default Spacer;