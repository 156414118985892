import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logos/WealthSphereLogoAndName.svg";
import {
  IconMenu2,
  //IconSettings,
  IconLogout,
  IconFileText
} from "@tabler/icons-react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box
} from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { COLORS } from "../../constants/constants";
import styles from "./PrivateNav.module.css";

function PrivateNav() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setIsDrawerOpen(newOpen);
  };

  const handleItemClick = () => {
    setIsDrawerOpen(false); // Close the drawer - using a handler due to the fact that react router will do nothing if its the same page
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/auth/signin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const DrawerList = (
    <Box sx={{ width: 250 }}>
      <List>
        {/* Blueprints */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/blueprints" onClick={handleItemClick}>
            <ListItemIcon>
              <IconFileText size={24} color={COLORS.primary300} />
            </ListItemIcon>
            <ListItemText primary="Blueprints" />
          </ListItemButton>
        </ListItem>

        {/* Settings */}
        {/* <ListItem disablePadding>
          <ListItemButton component={Link} to="/settings" onClick={handleItemClick}>
            <ListItemIcon>
              <IconSettings size={24} color={COLORS.primary300} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem> */}
      </List>

      {/* Divider */}
      <Divider />

      {/* Sign Out */}
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleSignOut}>
            <ListItemIcon>
              <IconLogout size={24} color={COLORS.primary300} />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className={styles.container}>
      {/* Logo */}
      <div>
        <Link to="/blueprints">
          <Logo className={styles.logo} />
        </Link>
      </div>

      {/* Menu Icon */}
      <div onClick={toggleDrawer(true)}>
        <IconMenu2 className={styles.icon} size={32} />
      </div>

      {/* Drawer */}
      <Drawer open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

export default PrivateNav;