import Joi from "joi";

export const newBlueprintSchema = Joi.object({
  blueprintYear: Joi.number()
    .integer()
    .min(1950)
    .max(new Date().getFullYear() + 20)
    .required(),
  blueprintName: Joi.string()
    .min(1)
    .max(64)
    .required()
});