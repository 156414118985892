/**
 * Checks if a name is valid.
 * Allows letters (including accented), hyphens, apostrophes, and spaces.
 * @param {string} name - The name to validate.
 * @returns {boolean} - Returns true if the name is valid, false otherwise.
 */
function nameIsValid(name) {
  const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ'-]+(?: [A-Za-zÀ-ÖØ-öø-ÿ'-]+)*$/;
  return nameRegex.test(name);
}

/**
 * Checks if an email is valid.
 * Checks for a standard email format.
 * @param {string} email - The email to validate.
 * @returns {boolean} - Returns true if the email is valid, false otherwise.
 */
function emailIsValid(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function passwordIsValid(value) {
  const specialChars = /[!@#$%^&*(),.?":{}|<>]/;

  // Check if the password is at least 8 characters long
  if (value.length < 8) {
    return false;
  }
  // Check if the password contains at least one number
  if (!/[0-9]/.test(value)) {
    return false;
  }
  // Check if the password contains both uppercase and lowercase letters
  if (!/[A-Z]/.test(value)) {
    return false;
  }
  if (!/[a-z]/.test(value)) {
    return false;
  }
  // Check if the password contains at least one special character
  if (!specialChars.test(value)) {
    return false;
  }

  // If all conditions are met, return true
  return true;
}

function confirmationCodeIsValid(code) {
  const regex = /^\d{6}$/;
  return regex.test(code);
}

function usernameIsValid(value) {
  // Check if the username is between 1 and 48 characters long
  if (value.length < 1 || value.length > 48) {
    return false;
  }

  // Ensure the username is in lowercase
  if (value !== value.toLowerCase()) {
    return false;
  }

  // Check if the username only contains allowed characters (letters and numbers)
  const allowedChars = /^[a-z0-9]+$/; // Only lowercase letters and numbers
  if (!allowedChars.test(value)) {
    return false;
  }

  // If all conditions are met, return true
  return true;
}

function blueprintNameIsValid(name) {
  const regex = /^.{1,64}$/; // Matches any character (except line breaks) between 1 and 64 times
  return typeof name === 'string' && regex.test(name);
}

function blueprintYearIsValid(year) {
  const currentYear = new Date().getFullYear();
  return (
    typeof year === "number" &&
    Number.isInteger(year) &&
    year >= 1950 &&
    year <= currentYear + 20
  );
}

export {
  nameIsValid,
  emailIsValid,
  passwordIsValid,
  confirmationCodeIsValid,
  usernameIsValid,
  blueprintNameIsValid,
  blueprintYearIsValid
};
