// External Libraries
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, InputAdornment } from "@mui/material";
import { IconSearch, IconPlus } from "@tabler/icons-react";
// Constants
import { COLORS } from "../../../constants/constants";
// Components
import LayoutSection from "../../../components/common/LayoutSection/LayoutSection";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import ActionMenu from "../../../components/common/ActionMenu/ActionMenu";
import ActionMenuItem from "../../../components/common/ActionMenuItem/ActionMenuItem";
import BlueprintTile from "../../../components/BlueprintTile/BlueprintTile";
// Hooks
import { useBlueprintList } from "../../../graphql/hooks/useBlueprintList";
import NewItemTile from "../../../components/common/NewItemTile/NewItemTile";

function AllBlueprints() {
  const navigate = useNavigate();
  const { blueprints, loading, error } = useBlueprintList();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBlueprints, setFilteredBlueprints] = useState([]);

  // console.log("AllBlueprints rendered");

  // Sync filteredBlueprints whenever blueprints or searchQuery changes
  useEffect(() => {
    if (!blueprints) return; // Skip if blueprints are not yet loaded
    const filtered = blueprints
      .filter(
        (blueprint) =>
          blueprint.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          String(blueprint.year).includes(searchQuery)
      )
      .sort((a, b) => Number(b.year) - Number(a.year)); // Sort by year (latest first)

    setFilteredBlueprints(filtered);
  }, [blueprints, searchQuery]);

  const handleNewBlueprintClick = () => navigate(`/blueprints/new`);

  const handleSearch = (e) => setSearchQuery(e.target.value);

  const renderContent = () => {
    if (loading) return <p>Loading blueprints...</p>;
    if (error) return <p>Error loading blueprints. Please try again later.</p>;
    if (blueprints && blueprints.length === 0) {
      // No blueprints exist
      return (
        <NewItemTile
          text="+ Create New Blueprint"
          onClick={handleNewBlueprintClick}
        />
      );
    }
    if (filteredBlueprints.length === 0) {
      // No blueprints match the search query
      return <p>No blueprints match your search criteria.</p>;
    }
    // Display the filtered blueprints
    return filteredBlueprints.map((blueprint) => (
      <BlueprintTile
        key={blueprint.id}
        year={blueprint.year}
        blueprintName={blueprint.name}
        blueprintId={blueprint.id}
      />
    ));
  };

  return (
    <>
      <LayoutSection customStyles={{ paddingTop: "72px" }}>
        <PageTitle
          title="Financial Blueprints"
          breakpointsToCenterText={["XS"]}
        />
      </LayoutSection>
      <LayoutSection customStyles={{ paddingTop: "16px" }}>
        <ActionMenu>
          <ActionMenuItem
            icon={<IconPlus color={COLORS.primary300} size={20} />}
            text="Create New Blueprint"
            onClick={handleNewBlueprintClick}
          />
        </ActionMenu>
      </LayoutSection>
      <LayoutSection
        customStyles={{ paddingTop: "80px", paddingBottom: "24px" }}
      >
        <TextField
          id="searchBlueprints"
          name="searchBlueprints"
          label="Search Blueprints"
          placeholder="Search by name or year"
          type="text"
          fullWidth
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          disabled={loading || blueprints.length === 0} // Disable search while loading
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconSearch size={20} />
                </InputAdornment>
              ),
            },
          }}
        />
      </LayoutSection>
      <LayoutSection customStyles={{ paddingBottom: "80px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {renderContent()}
        </div>
      </LayoutSection>
    </>
  );
}

export default AllBlueprints;
