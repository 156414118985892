import React from 'react';
import { useNavigate } from "react-router-dom";
import { IconClick } from "@tabler/icons-react";
import { COLORS } from '../../constants/constants';
import mergeClassNames from '../../util/mergeClassNames/mergeClassNames';
import styles from './BlueprintTile.module.css';

function BlueprintTile({year, blueprintName, blueprintId}) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blueprints/${blueprintId}`);
  };

  return (
    <div className={styles.tile} onClick={handleClick}>
        <div className={styles.textSection}>
            <div className={styles.year}><h6>{year}</h6></div>
            <p className={mergeClassNames("small-text-regular", styles.name)}>{blueprintName}</p>
        </div>
        <div className={styles.iconSection}><IconClick color={COLORS.accent300} size={24} /></div>
    </div>
  );
}

export default BlueprintTile;