import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import { IconFileText, IconCalendar } from "@tabler/icons-react";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { newBlueprintSchema } from "../../../util/validation/joiValidation/blueprintValidation";
import LayoutSection from "../../../components/common/LayoutSection/LayoutSection";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import InputGroup from "../../../components/common/InputGroup/InputGroup";
import { useCreateBlueprint } from "../../../graphql/hooks/useCreateBlueprint";
import FormAlert from "../../../components/common/FormAlert/FormAlert";
import Spacer from "../../../components/common/Spacer/Spacer";
// import styles from "./NewBlueprint.module.css";

// Utility function to handle numeric input changes
const handleNumericChange = (value, onChange) => {
  const numericValue = value === "" ? "" : parseInt(value, 10);
  if (!isNaN(numericValue) || value === "") {
    onChange(numericValue);
  }
};

function NewBlueprint() {
  const navigate = useNavigate();
  const { createBlueprint } = useCreateBlueprint();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(newBlueprintSchema),
    defaultValues: {
      blueprintYear: "",
      blueprintName: "",
    },
    mode: "onChange",
  });

  const onSubmit = async ({ blueprintYear, blueprintName }) => {
    if (submitLoading) {
      return;
    }

    setSubmitLoading(true);
    setSubmitError(null);

    try {
      // Create the blueprint
      const blueprint = await createBlueprint({
        input: { name: blueprintName, year: blueprintYear },
      });

      // Extract and validate the blueprint ID
      const blueprintId = blueprint?.blueprintInfo?.id;
      if (!blueprintId) {
        throw new Error(
          "Failed to get blueprintId from new blueprint operation"
        );
      }

      // Navigate to the new blueprint page
      navigate(`/blueprints/${blueprintId}`);
    } catch (error) {
      console.error("Error creating blueprint:", error);
      setSubmitError(
        "An error occurred while creating the blueprint. Please try again."
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <LayoutSection
        customStyles={{ paddingTop: "72px", paddingBottom: "40px" }}
      >
        <PageTitle
          title="Create New Blueprint"
          breakpointsToCenterText={["XS"]}
          subtitle="A blueprint is a yearly plan that captures all your expected income and expenses, helping you see how your money changes over time. Create a blueprint below."
        />
      </LayoutSection>
      <LayoutSection customStyles={{ marginBottom: "72px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup customStyle={{ marginBottom: "40px" }}>
            {/* Blueprint Year */}
            <Controller
              name="blueprintYear"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  required
                  inputRef={ref}
                  value={value}
                  onChange={(e) =>
                    handleNumericChange(e.target.value, onChange)
                  }
                  onBlur={onBlur}
                  type="text"
                  inputMode="numeric"
                  label="Blueprint Year"
                  placeholder="YYYY"
                  fullWidth
                  variant="outlined"
                  error={!!errors.blueprintYear}
                  helperText={
                    errors.blueprintYear
                      ? "Year must be between 1950 and 20 years from now."
                      : ""
                  }
                  slotProps={{
                    inputAdornment: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconCalendar size={24} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            />
            {/* Blueprint Name */}
            <Controller
              name="blueprintName"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  required
                  inputRef={ref}
                  value={value} // Controlled behavior
                  onChange={onChange}
                  onBlur={onBlur}
                  label="Blueprint Name"
                  placeholder="Blueprint Name"
                  fullWidth
                  variant="outlined"
                  error={!!errors.blueprintName}
                  helperText={
                    errors.blueprintName
                      ? "Name must be between 1 and 64 characters."
                      : ""
                  }
                  slotProps={{
                    inputAdornment: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconFileText size={24} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            />
          </InputGroup>
          <Spacer gap="40px">
            {submitError && (
              <FormAlert
                type="error"
                message="An error occurred while creating the blueprint. Please try again later."
              />
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              //disabled={submitLoading}
              fullWidth
              startIcon={
                submitLoading ? (
                  <CircularProgress
                    thickness={4}
                    size={16}
                    sx={{ color: "#FFFFFF" }}
                  />
                ) : null
              }
            >
              Create New Blueprint
            </Button>
          </Spacer>
        </form>
      </LayoutSection>
    </>
  );
}

export default NewBlueprint;
