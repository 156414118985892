import { useMutation } from "@apollo/client";
import { CREATE_BLUEPRINT } from "../mutations/createBlueprint"; // Import the mutation

export const useCreateBlueprint = () => {
  const [createBlueprintMutation, { loading, error }] = useMutation(CREATE_BLUEPRINT);

  const createBlueprint = async (variables) => {
    try {
      const { data } = await createBlueprintMutation({ variables });
      return data.createBlueprint; // Return the created blueprint data
    } catch (err) {
      throw err; // Let the caller handle errors
    }
  };

  return { createBlueprint, loading, error };
};
