import React from "react";
import { Routes, Route } from "react-router-dom";
import AllBlueprints from "../../pages/blueprints/AllBlueprints/AllBlueprints";
import NewBlueprint from "../../pages/blueprints/NewBlueprint/NewBlueprint";

function BlueprintsRoute() {
  return (
    <Routes>
      <Route index element={<AllBlueprints />} />
      <Route path="new" element={<NewBlueprint />} />
      <Route path=":blueprintId" element={<h1>View a specific blueprint page</h1>} />
      <Route path=":blueprintId/rename" element={<h1>Rename a blueprint page</h1>} />
      
      
      {/* 404 not found */}
      <Route path="*" element={<h1>this is the placeholder 404 page for the private routes</h1>} />
    </Routes>
  );
}

export default BlueprintsRoute;
