import { gql } from "@apollo/client";

/**
 * Subscription to listen for blueprint changes (CREATE or RENAME actions).
 * Pushes real-time updates for the client.
 */
export const ON_BLUEPRINT_INFO_CHANGE = gql`
  subscription OnBlueprintInfoChange {
    onBlueprintInfoChange {
      action
      blueprintInfo {
        id
        name
        year
      }
    }
  }
`;