import { useEffect, useState } from "react";
import { useApolloClient, useSubscription } from "@apollo/client";
import { LIST_BLUEPRINTS } from "../queries/listBlueprints";
import { ON_BLUEPRINT_INFO_CHANGE } from "../subscriptions/onBlueprintInfoChange";

export function useBlueprintList() {
  const client = useApolloClient();
  const [blueprints, setBlueprints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Fetch all blueprints with pagination
  useEffect(() => {
    let isSubscribed = true;

    async function fetchAllBlueprints() {
      //console.log("Fetching blueprint data using query.")
      setLoading(true);
      setError(false);

      try {
        let allBlueprints = [];
        let nextToken = null;

        // Paginated fetching
        do {
          const { data } = await client.query({
            query: LIST_BLUEPRINTS,
            variables: { nextToken },
            fetchPolicy: "no-cache",
          });

          const { items, nextToken: newNextToken } = data.listBlueprints;
          allBlueprints = [...allBlueprints, ...items];
          nextToken = newNextToken;
        } while (nextToken);

        if (isSubscribed) {
          setBlueprints(allBlueprints);
        }
      } catch (fetchError) {
        if (isSubscribed) {
          console.error("Error fetching blueprints:", fetchError);
          setError(true);
        }
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    }

    fetchAllBlueprints();

    return () => {
      isSubscribed = false;
    };
  }, [client]);

  // Handle subscription updates
  useSubscription(ON_BLUEPRINT_INFO_CHANGE, {
    onData: ({ data }) => {
      try {
        const { action, blueprintInfo } = data?.data?.onBlueprintInfoChange;

        if (!action || !blueprintInfo) {
          throw new Error("Invalid subscription data received.");
        }

        setBlueprints((previousBlueprints) => {
          const blueprintIndex = previousBlueprints.findIndex(
            (blueprint) => blueprint.id === blueprintInfo.id
          );

          switch (action) {
            case "CREATE":
              if (blueprintIndex === -1) {
                return [...previousBlueprints, blueprintInfo];
              }
              break;

            case "RENAME":
              if (blueprintIndex !== -1) {
                return previousBlueprints.map((existingBlueprint) =>
                  existingBlueprint.id === blueprintInfo.id
                    ? blueprintInfo
                    : existingBlueprint
                );
              }
              break;

            default:
              throw new Error(`Unhandled action type: ${action}`);
          }

          // No state change for handled actions without updates
          return previousBlueprints;
        });
      } catch (subscriptionError) {
        console.error("Subscription error:", subscriptionError);
        setError(true);
      }
    },
    onError: (subscriptionError) => {
      console.error("Subscription error:", subscriptionError);
      setError(true);
    },
  });

  return {
    blueprints,
    loading,
    error,
  };
}