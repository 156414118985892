import React from 'react';
import styles from './ActionMenuItem.module.css';
import mergeClassNames from '../../../util/mergeClassNames/mergeClassNames';

function ActionMenuItem({icon, text, onClick}) {
  return (
    <div className={styles.item} onClick={onClick}>
        {icon && icon}
        <p className={mergeClassNames("normal-text-regular", styles.text)}>{text}</p>
    </div>
  );
}

export default ActionMenuItem;