import { gql } from "@apollo/client";

/**
 * Mutation to create a new blueprint.
 * Returns the created blueprint along with the action type.
 */
export const CREATE_BLUEPRINT = gql`
  mutation CreateBlueprint($input: CreateBlueprintInput!) {
    createBlueprint(input: $input) {
      action
      blueprintInfo {
        id
        name
        year
      }
    }
  }
`;