import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { fetchAuthSession } from "@aws-amplify/auth";

// Environment variables
const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT; // Ensure this ends with /graphql
const region = process.env.REACT_APP_GRAPHQL_REGION; // e.g., us-east-1

// Authentication setup
const auth = {
  type: "AMAZON_COGNITO_USER_POOLS",
  jwtToken: async () => {
    //console.log("Fetching JWT token for apollo call.")
    const { tokens } = await fetchAuthSession({ forceRefresh: false });
    const token = tokens.accessToken.toString();
    return token;
  },
};

// HTTP link for queries and mutations
const httpLink = new HttpLink({ uri: graphqlEndpoint });

// Combine authentication and subscription links
const link = ApolloLink.from([
  createAuthLink({ url: graphqlEndpoint, region, auth }),
  createSubscriptionHandshakeLink({ url: graphqlEndpoint, region, auth }, httpLink),
]);


// Apollo Client setup
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache", // Ensure no stale data
    },
    query: {
      fetchPolicy: "no-cache", // Fetch fresh data
    },
    mutate: {
      fetchPolicy: "no-cache", // Fetch fresh data for mutations
    },
  },
});

export default client;