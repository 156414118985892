// Core imports
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react"; // Initialize Sentry
import { ApolloProvider } from "@apollo/client";
import client from "./apollo/apolloClient";

// MUI imports
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import muiTheme from "./muiTheme"; // Import your basic MUI theme

// Configuration imports
import "./config/amplify-config"; // Initialize Amplify

// Custom components and layouts
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import LandingPagesLayout from "./layouts/LandingPagesLayout/LandingPagesLayout";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";

// Route imports
import AuthRoute from "./routes/AuthRoute/AuthRoute";
import RootRoute from "./routes/RootRoute/RootRoute";
import PrivateRoute from "./routes/PrivateRoute/PrivateRoute";
import BlueprintsRoute from "./routes/BlueprintsRoute/BlueprintsRoute";
import PrivateLayout from "./layouts/PrivateLayout/PrivateLayout";

function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <Sentry.ErrorBoundary
      // to-do: replace this with a fallback componenet that will show on fatal app crashes
      fallback={
        <div style={{ textAlign: "center", marginTop: "56px" }}>
          <h1>Oops! Something went wrong.</h1>
          <br />
          <p>
            We have logged the error and are working to fix it. Please try
            refreshing the page.
          </p>
        </div>
      }
      showDialog
    >
      <ApolloProvider client={client}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <Router>
            <ScrollToTop />
            <SentryRoutes>
              {/* Landing Pages */}
              <Route element={<LandingPagesLayout />}>
                <Route path="/*" element={<RootRoute />} />
              </Route>
              {/* Auth Pages */}
              <Route element={<AuthLayout />}>
                <Route path="/auth/*" element={<AuthRoute />} />
              </Route>
              {/* Private Routes */}
              <Route element={<PrivateRoute />}>
                <Route element={<PrivateLayout />}>
                  <Route path="/blueprints/*" element={<BlueprintsRoute />} />
                </Route>
              </Route>
              {/* ---------- */}
            </SentryRoutes>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
