import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './PrivateLayout.module.css';
import LayoutSection from '../../components/common/LayoutSection/LayoutSection';
import FooterCopyrightBar from '../../components/FooterCopyrightBar/FooterCopyrightBar';
import PrivateFooter from '../../components/PrivateFooter/PrivateFooter';
import PrivateNav from '../../components/PrivateNav/PrivateNav';

function PrivateLayout() {
  return (
    <div className={styles.pageWrapper}>
        <header className={styles.header}>
            <LayoutSection customClasses={[styles.navSection]}>
                <PrivateNav />
            </LayoutSection>
        </header>
        <main className={styles.main}>
            <div className={styles.mainContentContainer}><Outlet /></div>
        </main>
        <footer className={styles.footer}>
            <LayoutSection customClasses={[styles.footerSection]}>
                <PrivateFooter />
            </LayoutSection>
            <LayoutSection customClasses={[styles.footerCopyrightBarSection]}>
                <FooterCopyrightBar />
            </LayoutSection>
        </footer>
    </div>
  );
}

export default PrivateLayout;