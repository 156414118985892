import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import LandingPageDesktopNav from "./LandingPageDesktopNav/LandingPageDesktopNav";
import LandingPageMobileNav from "./LandingPageMobileNav/LandingPageMobileNav";
import FullPageModal from "../common/FullPageModal/FullPageModal";
import useViewport from "../../hooks/useViewport/useViewport";
import NavSectionContainer from "../common/FullPageModal/NavSectionContainer/NavSectionContainer";
import NavContentContainer from "../common/FullPageModal/NavContentContainer/NavContentContainer";
import NavLink from "../common/FullPageModal/NavLink/NavLink";

function LandingPageNav() {
  const navigate = useNavigate();
  const [isNavModalOpen, setIsNavModalOpen] = useState(false);
  const { breakpoint } = useViewport();

  const handleNavOpen = () => setIsNavModalOpen(true);
  const handleNavClose = () => setIsNavModalOpen(false);

  return (
    <>
      {breakpoint === "L" ? (
        <LandingPageDesktopNav />
      ) : (
        <LandingPageMobileNav onMenuClick={handleNavOpen} />
      )}

      <FullPageModal isOpen={isNavModalOpen} onClose={handleNavClose}>
        <NavContentContainer>
          <div style={{display: "flex", flexDirection: "column", gap: "24px"}}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => navigate("/auth/signin")}
            >
              Log In
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => navigate("/auth/signup")}
            >
              Start Your Free Trial
            </Button>
          </div>
          <NavSectionContainer sectionTitle="Explore">
            <NavLink onClick={handleNavClose} text="Home" to="/" />
            <NavLink onClick={handleNavClose} text="Pricing" to="/pricing" />
            <NavLink
              onClick={handleNavClose}
              text="Newsletter"
              to="/newsletter"
            />
          </NavSectionContainer>
        </NavContentContainer>
      </FullPageModal>
    </>
  );
}

export default LandingPageNav;
