import React from 'react';
import styles from './PageTitle.module.css';
import mergeClassNames from '../../../util/mergeClassNames/mergeClassNames';
import useViewport from '../../../hooks/useViewport/useViewport';

function PageTitle({ title, subtitle, breakpointsToCenterText = [], alwaysCenterText = false }) {
  const { breakpoint } = useViewport();

  // Determine if the text should be centered
  const isCentered = alwaysCenterText || breakpointsToCenterText.includes(breakpoint);

  return (
    <div className={mergeClassNames(styles.container, isCentered && styles.centerText)}>
      <h3 className={mergeClassNames(styles.title)}>{title}</h3>
      {subtitle && <p className={mergeClassNames(styles.subtitle, "normal-text-regular")}>{subtitle}</p>}
    </div>
  );
}

export default PageTitle;