import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IconChevronDown } from '@tabler/icons-react';
import { COLORS } from "../../../constants/constants";

function ActionMenu ({children}) {
  return (
    <div>
      <Accordion
        sx={{
          backgroundColor: COLORS.accent100,
          boxShadow: "none",
          border: "none",
          borderBottom: `2px solid ${COLORS.primary100}`,
        }}
      >
        <AccordionSummary
          expandIcon={<IconChevronDown />}
          aria-controls="panel1-content"
          id="action-menu"
          sx={{
            "&:hover": {
              backgroundColor: COLORS.highlight100, // Hover effect on summary only
            },
          }}
        >
          Action Menu
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ActionMenu;