import React from 'react';
import mergeClassNames from '../../../util/mergeClassNames/mergeClassNames';
import styles from './NewItemTile.module.css';

function NewItemTile({text, onClick}) {
  return (
    <div className={mergeClassNames(styles.tile)} onClick={onClick}>
        <p className={mergeClassNames("normal-text-regular")}>{text}</p>
    </div>
  );
}

export default NewItemTile;