import { gql } from "@apollo/client";

/**
 * Query to fetch a paginated list of blueprints.
 * Accepts an optional `nextToken` for pagination.
 */
export const LIST_BLUEPRINTS = gql`
  query ListBlueprints($nextToken: String) {
    listBlueprints(nextToken: $nextToken) {
      items {
        id
        name
        year
      }
      nextToken
    }
  }
`;